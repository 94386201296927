import React from "react";
import cx from "classnames";
import * as styles from "./youtubeEmbed.module.scss";

const YoutubeEmbed = ({ youtubeVideo, className }) => {
  if (!youtubeVideo?.youtubeUrl) {
    return "";
  }

  const ytUrl = new URL(youtubeVideo?.youtubeUrl);
  let ytId;

  if (ytUrl) {
    if (ytUrl.host === "youtu.be") {
      ytId = ytUrl.pathname.replace("/", "");
    } else {
      ytId = ytUrl.searchParams.get("v");
    }
  }

  if (ytId) {
    return (
      <div
        className={cx(
          styles.videoResponsive,
          className,
          className?.includes("h-full") && styles.hFull
        )}
      >
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/${ytId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    );
  } else {
    console.warn("Not youtube video ID found.");
    return "";
  }
};
export default YoutubeEmbed;
