import React from "react";
import SanityImage from "gatsby-plugin-sanity-image";

const CaptionImage = ({ node }) => (
  <figure>
    {node.asset && <SanityImage {...node} alt={node.caption}></SanityImage>}
    {node.caption && (
      <figcaption className="small-title mt-2.5 text-right">
        {node.caption}
      </figcaption>
    )}
  </figure>
);
export default CaptionImage;
