import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import SanityImage from "gatsby-plugin-sanity-image";
import { sanity } from "../../client-config.js";
import cx from "classnames";
import CaptionImage from "./captionImage.js";

const serializers = {
  types: {
    /* eslint-disable-next-line react/display-name */
    authorReference: ({ node }) => <span>{node.author.name}</span>,
    mainImage: SanityImage,
    captionImage: CaptionImage,
  },
  marks: {
    // eslint-disable-next-line react/display-name
    link: ({ mark, children }) => {
      const { blank, href } = mark;
      return blank ? (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <a href={href}>{children}</a>
      );
    },
  },
};

const PortableText = ({ blocks, className }) => (
  <div className={cx(className, "rte")}>
    <BlockContent
      blocks={blocks}
      serializers={serializers}
      {...sanity}
      imageOptions={{ w: 860 }}
    />
  </div>
);

export default PortableText;
